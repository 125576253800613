import { initializeApp } from "firebase/app";
import {  getFirestore } from 'firebase/firestore';

var firebaseConfig = {
  apiKey: "AIzaSyD5ZyifkxdOYCP0CnSsXF8NMP6fmeT8Jj0",
  authDomain: "gss-invoices.firebaseapp.com",
  projectId: "gss-invoices",
  storageBucket: "gss-invoices.appspot.com",
  messagingSenderId: "580489458061",
  appId: "1:580489458061:web:5520e881666cba59918dc4"
};

const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);